import React, {useEffect, useReducer, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import LoadingCircle from "../../components/LoadingCircle";
import {MSOGFormContextProvider} from "../../services/MSOGFormContext";
import formReducer from "../../reducers/deliveryFormReducer";
import {useDepServices} from "../../services/DepServicesContext";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import ConfirmForm from "./ConfirmForm";
import {setApplayerOrientationLock} from "../../utilitis/helper";

const Confirm = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {deliveriesService} = useDepServices();
    const reducer = formReducer;
    const [formValues, formDispatch] = useReducer(reducer, {});
    const [unconfirmedJobs, setUnconfirmedJobs] = useState();
    const [isDirty, setIsDirty] = useState(true);


    useEffect( () => {

        setApplayerOrientationLock(false);

        return () => {
            setApplayerOrientationLock(true);
        }
    },[]);

    useEffect(() => {
        const fetchData = async () => {
            const dbUnconfirmedJobs = await deliveriesService.getUnconfirmedJobs();

            if (dbUnconfirmedJobs.length > 0) {
                formDispatch({type:'initial_value', payload:{job_guids:[], job_extras:[...dbUnconfirmedJobs[0].job_extras], line_items:[]}});
                setUnconfirmedJobs(dbUnconfirmedJobs);
            } else {
                dispatch(push('/deliveries'));
            }
        }

        if (isDirty) {
            fetchData();
            setIsDirty(false);
        }
    }, [isDirty]);

    const handleSubmit = () => {
        setIsDirty(true);
    }

    const renderForm = () => {
        return (
            <div className="data-list-content">
                <MSOGFormContextProvider formValues={formValues} dispatch={formDispatch}>
                    <ConfirmForm job={unconfirmedJobs[0]} handler={handleSubmit}></ConfirmForm>
                </MSOGFormContextProvider>
            </div>
        );
    }

    const renderLoading = () => {
        return (
            <div className="data-loading"><h3>Loading... </h3><LoadingCircle isSubmitting={true} /></div>
        );
    }

    return (
        <Container fluid>
            <div className="data-list">
                <div className="data-list-header">
                    <h3>{t("ui:confirm.header")}</h3>
                </div>
                {unconfirmedJobs ? renderForm() : renderLoading()}
            </div>
        </Container>
    );
}

export default Confirm;
