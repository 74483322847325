import {createStore, applyMiddleware} from 'redux';
import {createBrowserHistory} from 'history';
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from "./reducer";
import {persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import {DELIVERY_TYPE_SINGLE} from "./constains/types";
import {InitialAppState} from "./reducers/appStateReducer";
import {InitialRuntimeState} from "./reducers/runtimeStateReducer";
import {InitialDeliveryState} from "./reducers/deliveryStateReducer";
import {SET_DELIVERY_LIST_CHANGED_AT, SET_SESSION_CHANGED_AT} from "./constains/appStateActions";

export const history = createBrowserHistory()

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
    blacklist: ['router','runtime']
};

const preloadedState =  {
    appStateReducer: {...InitialAppState},
    deliveryStateReducer: {...InitialDeliveryState},
    runtime: {...InitialRuntimeState}
};

const composedEnhancer = composeWithDevTools(applyMiddleware(routerMiddleware(history),thunkMiddleware));

export function configureStore() {
    return createStore(persistReducer(persistConfig, reducer(history)), preloadedState, composedEnhancer);
}

export const selectPathName = state => state.router.location.pathname;

const selectAppState = state => state.appStateReducer;
export const selectVehicleCheck = state => selectAppState(state).isVehicleCheckRequired;
export const selectAppSettings = state => selectAppState(state).appSettings;
export const selectLoginState = state => selectAppState(state).isLogin;
export const selectQueueSelectedState = state => selectAppState(state).isQueueSelected;
export const selectVehicleCheckedState = state => selectAppState(state).isVehicleChecked;
export const selectDisplayMode = state => selectAppState(state).displayMode;
export const selectQueryParams = state => selectAppState(state).queryParams;

const selectRuntimeState = state => state.runtime;
export const selectLoadingState = state => selectRuntimeState(state).isLoading !== 0;
export const selectOnlineState = state => selectRuntimeState(state).isOnline;
export const selectSyncingState = state => selectRuntimeState(state).isSyncing !== 0;
export const selectCameraOpenState = state => selectRuntimeState(state).isCameraOpen;
export const selectBackgroundServiceState = state => selectRuntimeState(state).isBackgroundServiceEnable;
export const selectLocationUploadAt = state => selectRuntimeState(state).locationUploadAt;
export const selectSWInitState = state => selectRuntimeState(state).isServiceWorkerInitialized;
export const selectSWUpdateState = state => selectRuntimeState(state).isServiceWorkerUpdated;
export const selectSWRegistration = state => selectRuntimeState(state).serviceWorkerRegistration;
export const selectSessionUpdateTime = state => selectRuntimeState(state)[SET_SESSION_CHANGED_AT];
export const selectDeliveryListUpdateTime = state => selectRuntimeState(state)[SET_DELIVERY_LIST_CHANGED_AT];

const selectDeliveryState = state => state.deliveryStateReducer;
export const selectNewJobCount = state => selectDeliveryState(state).newJobCount;
export const selectDeliveryType = state => selectDeliveryState(state).deliveryType;
export const selectPrecheckList = state => selectDeliveryState(state).precheckList;
export const selectUserQueues = state => selectDeliveryState(state).userqueues;
