import React, {useRef} from "react";
import {Form} from "react-bootstrap";
import {globalToLocalFieldGrid} from "../../utilitis/helper";
import {useTranslation} from "react-i18next";

const TextArea = ({ fieldname = '', description = '', fieldid = '', required = 0, fieldvalue = '', editable = true, fieldcss = {}, handler = function () {}, register = function () {}, errors = {}}) => {
    //const textarea = useRef();

    const [labelCol, fieldCol, labelColTablet, fieldColTablet] = globalToLocalFieldGrid(fieldcss),
        {t} = useTranslation(),
        displayname = description && description !== '' ? description : fieldname;

    return (
        <Form.Group className="row">
            <div className={`col-${labelCol} col-md-${labelColTablet} text-left`}>
                <label htmlFor={fieldid} className={`col-form-label col-${labelCol} col-md-${labelColTablet} ps-3`}>{displayname}{required == 1 ? ' *' : ''}</label>
            </div>
            <div className={`col-${fieldCol} col-md-${fieldColTablet}`}>
                <textarea className={`${errors && errors[fieldid] ? "is-invalid" : ""} form-control`} id={fieldid}  data-guid={fieldid} defaultValue={fieldvalue} {...register(fieldid, { required: required == 1, onChange: handler})} disabled={(editable)? "" : "disabled"}/>
                <div className="invalid-feedback">{ t("ui:generic.input.invalid_message", {name: displayname})}</div>
            </div>
        </Form.Group>
    );
}

export default TextArea;
