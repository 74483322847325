import React, {useEffect, useState, memo, useRef, forwardRef, useImperativeHandle} from "react";
import Dexie from "dexie";
import Paginator from "../../../components/Paginator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library } from '@fortawesome/fontawesome-svg-core';
import {fas, faEdit, faPlus,faMinus,faCheck,faChevronDown, faBoxes,faExclamationCircle,faQuestionCircle,faExchangeAlt,faBan} from '@fortawesome/free-solid-svg-icons';
import LineItemInput from "./LineItemInput";
import {isTablet,isBrowser,isMobileOnly} from "react-device-detect";
import {PhotoInput} from "../../inputs/PhotoInput";
import {useForm} from "react-hook-form";
import UseFormLineItems from "../../../components/UseFormLineItems";
import {useDeliveryForm} from "../../../services/DeliveryFormContext";
import {Button, Col, ListGroup} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectAppSettings} from "../../../store";


library.add(fas,faEdit, faPlus,faMinus,faCheck,faChevronDown,faBoxes,faExclamationCircle,faQuestionCircle,faExchangeAlt,faBan);

const LineItemComponent = memo(forwardRef(({itemGuid,itemIndex,lineActions,lineItemImageLimit, statusHandler}, ref) => {

    const {formValues, dispatch} = useDeliveryForm();
    const item = formValues.line_items.find(element => element.guid === itemGuid);
    const appSettings = useSelector(selectAppSettings);
    const {register, control} = useForm();
    const photoInputRef = useRef();
    const itemRef = useRef();
    const {handleChange} = UseFormLineItems();
    const [camBtn,setCamBtn] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [refNO, setRefNO] = useState(item.refno);


    useImperativeHandle(ref, () => ({
        inactivate() {
            setIsActive(false);
        },

        isDirty() {
            //the field was dirty if state refno not equals to item refno
            setRefNO(item.refno);
            return refNO !== item.refno;
        },

        isEditable() {
            return isEditable()
        },

        scrollToRef() {
            itemRef.current.scrollIntoView();
        }

    }));

    const isEditable = () => {
        const [maxQuantity,availableActions] = getAvailableQuantityAndActions()
        return availableActions.length > 0 && maxQuantity > 0 && ( item.adjustment.length === 0 || item.adjustment.length !== lineActions.length && appSettings.lineitem_selection_mode === "multiple" ) && isActive
    }

    const toggleIsActive = e => {
        setIsActive(! isActive);
        statusHandler({status: !isActive, guid: itemGuid})
        e.preventDefault();
    }

    const startPhotoCaptureHandler = (e) => {
        photoInputRef.current.startCaptureHandler(e);
        setIsActive(true);
    }


    const closeLineItemMHandler= (e,idName,guid) =>{
        let targetDiv = document.querySelector("#item-"+idName);
        let targetRange = document.querySelector("#linerange-"+guid);
       // if(e.currentTarget !== undefined && e.currentTarget.classList.contains("btn-line-product") !== undefined){
      // !e.currentTarget.classList.contains("active") ? e.currentTarget.classList.add('active') : e.currentTarget.classList.remove('active')
    //}
        e.persist();
        return(!targetDiv.classList.contains("active") && !targetRange.classList.contains("active")? targetDiv.classList.add('active') && targetRange.classList.add("active")  : targetDiv.classList.remove('active') && targetRange.classList.remove("active"));
    }

    const isActioned = ()=>{
        return item.adjustment.length > 0 ? "actioned":  "";
    }

    const renderInputs = () => {

        const [maxQuantity,availableActions] = getAvailableQuantityAndActions()
        return (
            <div id={"linerange-"+item.guid} className={`line-item-elements range-container ${isActive || (formValues.job_status !== 2 && item.adjustment.length > 0) ? 'active':''}`}>
                <div className={"range-triangle "+ isActioned()} />
                <PhotoInput key={`webcam-${item.guid}`} itemindex={itemIndex} fieldname={item.value.ItemCode} fieldid={item.guid} required={0} fieldvalue={item.photos ?? []} imageWidth={640} imageHeight={460} handler={handleChange} control={control} register={register} ref={photoInputRef}/>
                { isEditable() && <LineItemInput index={`input-${item.guid}-new`} id={item.guid} adjustment={{quantity: 0, action:""}} maxQuantity={maxQuantity} availableActions={availableActions} allActions={lineActions} handler={handleChange}></LineItemInput>}
                {item.adjustment.map((element) => <LineItemInput key={`input-${item.guid}-${element.action}`} index={`${item.guid}-${element.action}`} id={item.guid} adjustment={element} maxQuantity={maxQuantity} availableActions={availableActions} allActions={lineActions} handler={handleChange}></LineItemInput>)}
            </div>
        );
    }

    const getAvailableQuantityAndActions = () => {
        const adjustmentQuantity = (item.adjustment.length > 0) ? item.adjustment.reduce((accumulator, element) => accumulator + element.quantity, 0) : 0,
            maxQuantity = parseInt(item.value.Quantity) - adjustmentQuantity,
            availableActions = lineActions.filter(action => (action.operator !== "=" || item.adjustment.length === 0) && item.adjustment.findIndex(element => element.action === action.action) === -1)
        return [maxQuantity, availableActions]
    }

    const content =
        (isTablet || isBrowser) ?
            <ListGroup.Item key={"linelist-"+item.guid+"-"+itemIndex} className={"item-line pe-0 ps-0 "+ isActioned() } ref={itemRef}>
                <div className="line-item-elements ms-0">
                    <div className="modified-indicator">{isActioned()?<FontAwesomeIcon  icon={["fas", "check"]}  className="mr-3 actioned-tick" />:""}</div>
                    <div className="product-title">{item.value.ItemCode}</div>
                    <div className="product-desc">{item.value.Description}</div>
                    <div className="product-quantity">
                        {item.value.Quantity} {item.value.Unit}
                    </div>
                    <div className="product-actions">
                        <Button disabled={(item.photos && item.photos.length >= lineItemImageLimit) || formValues.job_status !== 2} data-guid={item.guid} onClick={startPhotoCaptureHandler} variant={"secondary"} className={'me-3'}>
                            <span className="fa-layers fa-fw"><FontAwesomeIcon icon={["fas", "camera"]} size={"lg"}/>{item.photos && item.photos.length >= lineItemImageLimit || formValues.job_status !== 2 ? <FontAwesomeIcon color={"red"} size={"lg"} icon={["fas", "ban"]}/> : <></>}</span>
                        </Button>
                        <Button id={`btn-lineitem-${item.guid}`} data-id={item.guid} type="button"  onClick={toggleIsActive} disabled={formValues.job_status !== 2}>
                            <FontAwesomeIcon  icon={["fas", "edit"]}  />
                        </Button>
                    </div>
                </div>
                {renderInputs()}
            </ListGroup.Item> :
            <ListGroup.Item key={"linelist-"+item.guid+"-"+itemIndex} className={"item-line mobile "+ isActioned()} ref={itemRef}>
                <div className="item-line-top">
                    <div className="line-item-product">
                        <button id={`btn-${item.value.ItemCode}-item`} className={`btn-line-product ${isActive ? 'active' : ''} ` + isActioned()} data-id={item.value.ItemCode} onClick={toggleIsActive}>
                            {isActioned()?<FontAwesomeIcon  icon={["fas", "check"]}  className="mr-3 actioned-tick" />:""}
                            <span> {item.value.ItemCode}</span>
                            <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
                            <FontAwesomeIcon icon={["fas", "chevron-down"]}/>
                        </button>
                    </div>
                </div>
                <div id={`item-${item.value.ItemCode}`} className={`line-item-content ${isActive ? 'active' : ''}`}>
                    <div className="line-item-desc">
                        <h5>Description</h5>
                        <span>{item.value.Description}</span>
                    </div>
                    <div className="quantity-holder">
                        <h5>Quantity</h5>
                        <span>{item.value.Quantity} {item.value.Unit}</span>
                    </div>
                    <div className="btn-holder">
                        <h5>Actions</h5>
                        <span>
                        <Button disabled={(item.photos && item.photos.length >= lineItemImageLimit) || formValues.job_status !== 2} data-guid={item.guid} onClick={startPhotoCaptureHandler} variant={"secondary"} className={'me-3'}>
                            <span className="fa-layers fa-fw"><FontAwesomeIcon icon={["fas", "camera"]} size={"lg"}/>{item.photos && item.photos.length >= lineItemImageLimit ? <FontAwesomeIcon color={"red"} size={"lg"} icon={["fas", "ban"]}/> : <></>}</span>
                        </Button>
                        <Button id={`btn-lineitem-${item.guid}`} data-id={item.guid} type="button"  onClick={toggleIsActive} disabled={formValues.job_status !== 2}>
                            <FontAwesomeIcon  icon={["fas", "edit"]}  />
                        </Button>
                        </span>
                    </div>
                    {renderInputs()}
                </div>
            </ListGroup.Item>;
    return content;
}));

export default LineItemComponent;
