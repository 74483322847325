import React, {createRef, useCallback, useEffect, useRef, useState} from 'react';
import { Document, Page,pdfjs } from 'react-pdf';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas,faFilePdf} from '@fortawesome/free-solid-svg-icons';
import UsePdfRender from "./UsePdfRender";
import {Button, Col, Modal} from "react-bootstrap";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import Paginator from "./Paginator";
import ImageViewer from "./ImageViewer";

library.add(fas,faFilePdf);

const PdfViewer = ({jobPDF, jobExtras}) => {
   // const [pageNumber, setPageNumber] = useState(1);
    const [pdfImages,setPdfImages] = useState([]);
    const {convertPDFToImages} = UsePdfRender();
    const [fullPDF,setFullPDF] = useState(false);


    useEffect( () => {

        const fetchPDF = async () =>
        {
            if (jobPDF) {

                const result = await convertPDFToImages(jobPDF, jobExtras,document.querySelector('div#invoicepdfviewer'));
                setPdfImages(result);
            }
        }

        fetchPDF();

    },[]);


    const fullPDFHandler = ()=>{

        return (fullPDF === false ? setFullPDF(true) : setFullPDF(false));
    }

    return(
        <>
            <Button variant={"primary"} onClick={fullPDFHandler} size={"lg"}>
                <FontAwesomeIcon icon={["fas", "file-pdf"]}/>
                <span className={"btn-label"}>PDF preview</span>
            </Button>
            <div id="invoicepdfviewer" className="d-none"></div>
            <Modal fullscreen={'sm-down'} size={'xl'} show={fullPDF} onHide={() => setFullPDF(false)} centered>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <div className="pdf-scroller" style={{maxWidth:window.innerWidth+'px', maxHeight: '90vh'}}>
                        <div className="pdf-viewport" style={{maxWidth:'inherit', maxHeight: 'inherit'}}>
                            {pdfImages.map(([image,width,height,scale], index) =>
                                <ImageViewer key={index} src={image} width={width} height={height} scale={scale} style={{maxHeight:window.innerHeight+'px'}}/>
                                // <div key={index} style={{overflow: "hidden"}}>
                                // <img key={`invoicepdf-${index}`} src={image} style={{width: '100%'}} />
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PdfViewer;


